/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Hai Nguyen",
  title: "Hi there, I'm Hai",
  subTitle: emoji(
    "I am a technological enthusiasm person, with more than 15 years of experience in software development, from startups to big corporations. I spend most of my time working for financial services organizations like banking and insurance. I was lucky to participate in building a digital bank from the ground up, and working for big corporations also brought me lots of valuable experience. My keywords include microservice, cloud-native, digital transformation, and agile software development."  ),
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/in/hainguyenminh/",
  twitter: "https://twitter.com/minhhai8507",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "Engineering Manager | Solution Architect",
  skills: [
    emoji(
      "⚡ Cloud native, microservice solution design"
    ),
    emoji("⚡ Engineering management"),
    emoji(
      "⚡ Digital banking, digital transformation"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Infrastructure as code",
      fontAwesomeClassname: "fas fa-code"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "android",
      fontAwesomeClassname: "fab fa-android"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "AWS Certified Solutions Architect - Associate",
      logo: require("./assets/images/aws.png"),
      subHeader: "Amazon Web Services",
      duration: "2022 - 2025"
    },
    {
      schoolName: "CKA: Certified Kubernetes Administrator",
      logo: require("./assets/images/cka.png"),
      subHeader: "The Linux Foundation",
      duration: "2022 - 2025"
    },
    {
      schoolName: "Vietnam National University, Hanoi",
      logo: require("./assets/images/vnu-logo.png"),
      subHeader: "Master of Science in Information System",
      duration: "2009 - 2011"
    },
    {
      schoolName: "Military Technical Academy",
      logo: require("./assets/images/mta-logo.png"),
      subHeader: "Bachelor of Engineering in Information System",
      duration: "2003 - 2008"
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Engineering Managerment", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Solution Architecture",
      progressPercentage: "90%"
    },
    {
      Stack: "Microservices",
      progressPercentage: "90%"
    },
    {
      Stack: "SOA",
      progressPercentage: "70%"
    },
    {
      Stack: "Software Craftmanship",
      progressPercentage: "80%"
    },
    {
      Stack: "Agile Software Development",
      progressPercentage: "90%"
    },
    {
      Stack: "Cloud, AWS, Azure",
      progressPercentage: "70%"
    },


  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
     {
      role: "First Vice President, Head of Digital Engineering and Architecture",
      company: "Security Bank Corporation",
      companylogo: require("./assets/images/SBC-logo.jpg"),
      date: "May 2023 – Now",
      descBullets: [ "Leading Digital Engineering for Retail Technology, including:",
        "Digital Architecture",
        "Digital Assisted Channels Engineering",
        "Digital Channels Engineering",
        "Digital Platform Engineering"
      ]
    },
    {
      role: "Engineer Manager",
      company: "NAB",
      companylogo: require("./assets/images/nab-logo.png"),
      date: "June 2020 – March 2023",
      descBullets: [ "People leader, growing people techical and soft skills",
        "Making sure engeineering practices are applied to maintain good quality and deivery efficiency",
        "Agile software development"
      ]
    },
    {
      role: "Senior Solution Architect",
      company: "Manulife",
      companylogo: require("./assets/images/manulife.png"),
      date: "Mar 2019 – May 2020",
      descBullets: [ "Regional solution design for ePOS HK and ePOS Singapore web apps.",
        "Making sure the development team follow the design.",
        "Making desision on the technologies choices and coaching/mentoring the lead developers."]
    },
    {
      role: "Head of engineering/ Solution Architect",
      company: "Commonwealth Bank of Australia",
      companylogo: require("./assets/images/cba-logo.jpg"),
      date: "Jul 2014 – Feb 2019",
      descBullets: [ "End to end solution design for Tyme Bank, microservices, eventing, serving 3 millions customers now.",
      "Setup the process and practices for engineering i.e. CI/CD automation, software craftmanship training.",
      "People leader, growing people technical and soft skills"]    },
    {
      role: "Sernior Executive Officer",
      company: "Vietnam International Bank",
      companylogo: require("./assets/images/vib-logo.png"),
      date: "Oct 2013 – June 2014",
      descBullets: [ "Channels, Payments and Integration services.",
      "Internet Banking, SMS, Call center and other internal systems."]    
    }]
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements 🏆 "),
  subtitle:
    "Achievements and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Droid Con Vietnam 2017",
      subtitle:
        "Taking at Droidcon Vietnam about the practices to build a good and quality enterprise mobile app.",
      image: require("./assets/images/droidconvn.jpg"),
      footerLink: [
        {
          name: "Speaker"
        },
        {
          name: "Android",
        },
        {
          name: "Software craftmanship",
        }
      ]
    },
    {
      title: "Digital Bank Hackathon",
      subtitle:
        "Won both people vote and business vote for the chatbot solution, integrated with Facebook messenger",
      image: require("./assets/images/hackathon.jpg"),
      footerLink: [
        {
          name: "Hackathon"
        },
        {
          name: "Innovation"
        }
      ]
    },

    {
      title: "Microservices Meetup",
      subtitle: "Sharing my experience in building digital bank from the ground up and lessons learned from that",
      image: require("./assets/images/microservices.jpeg"),
      footerLink: [
        {
          name: "Speaker"},
        {
          name: "Microservices"
        },
        {
          name: "Troubleshooting in distriuted environment"
        }
      ]
    },
    {
      title: "Vietnam Web Summit 2022",
      subtitle: "Sharing my experience at NAB on \"How NAB builds technology to support 8 million customers\"",
      image: require("./assets/images/vnws-2022.jpeg"),
      footerLink: [
        {
          name: "Speaker"},
        {
          name: "Vietnam Web Summit 2022"
        },
        {
          name: "Enterprise Engineering"
        }
      ]
    },
    {
      title: "NAB HONOUR",
      subtitle: "NAB Honour is the highest accolade to achieve at NAB. It celebrates colleagues who represent the best of the best, and are outstanding role models of How We Work.",
      image: require("./assets/images/nab-honour.jpg"),
      footerLink: [
        {
          name: "HONOUR"},
        {
          name: "Best of the best"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me"),
  subtitle:
  emoji("It's always good to meet new friends and have a cup of coffee 😉"),
  number: emoji("☎️ +84972326076"),
  email_address: emoji("📨 me@hainguyenminh.com")
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  achievementSection,
  contactInfo,
  twitterDetails
};
